@media screen and (max-width: 768px) and (min-width: 320px) {

    .hbt-method-text {
        font-size: 35px;
    }

    .method-container-reverse {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .method-container-reverse>div>div {
        margin: 0px !important;
    }

    .method-container {
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .method-container>div {
        overflow: hidden;
    }

    .method-container>div>img {
        float: none !important;
        margin: 0 auto;
    }

    .image-revers {
        width: 254px;
        height: 159px;
        margin: 0 auto;
    }

}