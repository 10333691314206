@mixin white-gradient {
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

$animationSpeed: 35s;

.break {
	width: 50px;
}

@mixin red-gradient {
	background: linear-gradient(to right, #E1003C 0%, rgba(243, 1, 1, 0) 100%);
}

.slider {
	@keyframes scroll {
		100% {
			transform: translateX(0);
		}

		0% {
			transform: translateX(calc(-250px * 7))
		}
	}

	background: #f1f2f2;
	// box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 100px;
	// margin: auto;
	overflow:hidden;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	// align-content: space-between;



	&::before,
	&::after {
		@include red-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 150px;
		z-index: 2;
	}

	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}

	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 30);
	}

	.slide {
		height: 100px;
		width: 250px;
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}
}


.slider-rev {
	@keyframes scroll-rev {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(calc(-250px * 7))
		}
	}

	background: #f1f2f2;
	height: 100px;
	margin-top: 3rem;
	overflow:hidden;
	position: relative;
	width: 100%;

	&::before,
	&::after {
		@include red-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 150px;
		z-index: 2;
	}

	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}

	.slide-track {
		animation: scroll-rev $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 30);
	}

	.slide {
		height: 100px;
		width: 250px;
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}
}