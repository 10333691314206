.card {
  animation: slide-in 1.0s ease-in-out;
}

.horse {
  animation: slide-right 1.0s ease-in-out;
}

.horse2 {
  animation: slide-left 1.0s ease-in-out;
}

.left {
  animation: slide-left 1.0s ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slide-small {
  0% {
    transform: translateY(-25%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.box {
    box-shadow: 0px 25px 25px 25px rgb(0 0 0 / 0.15);
    margin-top: 50px;
    background-color:white;
}


@keyframes slide-right {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

