
@media screen and (max-width: 1440px) and (min-width: 1024px) {
    .testimonial-quote {
        font-size: 15px;
    }

    .testimonial-img {
        width: 110px;
        height: 110px;
        border-radius: 60px;
        margin-top: 50px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .testimonial-quote {
        font-size: 15px;
    }

    .testimonial-img {
        width: 110px;
        height: 110px;
        border-radius: 60px;
        margin-top: 50px;
    }
}

@media screen and (max-width: 768px) and (min-width: 320px) {

    .home-hbt-txt {
        font-size: 35px;
    }

    .home-first-slider>p {
        font-size: 15px;
    }

    .home-first-slider>p {
        font-size: 20px;
    }

    .home-first-slider-img {
        width: 150px;
        height: 150px;
    }

    .testimonial-img {
        margin-top: 10px;
    }

    .testimonial-quote {
        font-size: 12px;
    }

    .testimonial-info {
        font-size: 12px;
        margin-top: 5px;
    }
}