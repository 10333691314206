.post-container {
	width: 600px;
	margin: 0 auto;
	display: block;
}

.webinar-img {
	margin: 0 auto;
	width: 500px;
	height: 300px;
}

.fullstack-img {
	margin: 0 auto;
	width: 500px;
	height: 500px;
}

.conc-desc>ul,
.price-desc>ul {
	list-style-type: disc;
	text-align: left;
}

.format-conc>ul {
	list-style-type: auto;
}

.post-text {
	margin-top: 20px;
	margin-bottom: 20px;
}

.text-underline {
	text-decoration: underline;
}
.link-styles{
	color: rgb(255, 0, 0);
}

.post-text>a {
	text-decoration: underline;
}