.custom-styles {
    width: 72%;
    margin: 20px auto;
    background-color: #e1003c;
    color: #ffffff;
    border-radius: 10px;
    padding: 50px;
}

.custom-styles>h2 {
    color: #000000;
}

@media screen and (max-width: 768px) and (min-width: 320px) {

    .custom-styles>h2,
    .programs-hbt-school {
        font-size: 25px;
    }

    .programs-container-reverse {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .programs-container {
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .img-program-container>img {
        width: 220px;
        height: 260px;
        margin: 0 auto;
    }

    .mobile-center {
        text-align: center;
    }

    .curicula-btn {
        margin-top: 30px;
    }

    .curicula-btn>a>button {
        margin: 0 auto;
        display: block;
    }
}